













import { Component, Vue } from "vue-property-decorator";

import BlogPostAppModel from "@/api/blog_post.app.model";

import PostsCarousel from "@/layouts/components/blog/user/PostsCarousel.vue";

import { BlogPost, IdealMktCategories } from "@/interfaces/blog_post";
import { inflateEntity } from "@/api/_request";

@Component({ components: { PostsCarousel } })
export default class BlogPostsMarketing extends Vue {
	blogPostModel = BlogPostAppModel;

	posts: BlogPost[] = [];

	mounted() {
		this.loadPosts();
	}

	async loadPosts() {
		this.$store.dispatch("app/showLoading");

		try {
			const posts = (await this.blogPostModel.search({
				join: { field: "mainCategory" },
				filter: { field: "mainCategory.name", operator: "$eq", value: IdealMktCategories.MARKETING },
			})) as BlogPost[];

			this.posts = posts.map(post => inflateEntity(post) as BlogPost);
		} catch (error) {
			console.error(error);
		}

		this.$store.dispatch("app/hideLoading");
	}
}
